.Nav {
  background-color: black;
  color: #f6f0c4;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  transition: all 1s ease;
}

.background-image {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  overflow: hidden;
}

.background-image img {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.navpage-container {
  position: absolute;
  z-index: 2;
  width: 100vw;
  margin: 120px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.navpage-link {
  font-family: "Londrina Outline";
  margin: auto;
  font-size: clamp(3rem, -3.0714rem + 10.4286vw, 7rem);
  /* text-align: center; */
  width: 90%;
  overflow-x: hidden;
  /* transform: rotate(-15deg); */
}

.navpage-link:nth-child(1) {
  /* margin-left: 0em; */
  margin-top: 0em;
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}

.navpage-link:nth-child(2) {
  /* margin-left: 0.5em; */
  animation: 0.5s ease-out 100ms 1 slideInFromBottom;
}
.navpage-link:nth-child(3) {
  /* margin-left: 0em; */
  animation: 0.5s ease-out 200ms 1 slideInFromBottom;
}
.navpage-link:nth-child(4) {
  /* margin-left: 1em; */
  animation: 0.5s ease-out 300ms 1 slideInFromBottom;
}

.navpage-link:hover {
  font-family: "Londrina Solid";
}
