.selected-works {
  position: absolute;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: scroll;
}

.work {
  display: flex;
  flex-direction: row;
  width: calc(5 * 33vw);
  height: 100vh;
  color: white;
  height: hidden;
}

.menuname {
  display: "flex";
  align-self: "center";
  width: 100%;
}

.name-container {
  display: flex;
  flex: 4;
  color: white;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.4rem;
  padding: 1.5em;
  font-family: "Poppins";
  width: 100%;
}

.bar {
  position: inline-block;
  height: 40vh;
  min-width: 200%;
  color: white;
  font-size: 80px;
  display: flex;
  justify-content: space-evenly;
  font-weight: 900;
  padding: 5rem;
}

.bar_content {
  display: block;
  transform: translateX(100%);
  animation: move 35s linear infinite;
  white-space: nowrap;
  text-transform: uppercase;
}

/* Create the animation */
@keyframes move {
  to {
    transform: translateX(-100%);
  }
}

/* media queries */
@media all and (max-width: 1100px) and (min-width: 0px) {
  .selected-works {
    overflow: scroll;
  }

  .work {
    flex-direction: column;
    width: 100vw;
  }

  .icon {
    font-size: 2em;
  }
}
