.Home {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 0;
}

.cooltext {
  position: absolute;
  left: 0;
  z-index: -1;
  height: 80vh;
  width: 100vw;
  transform: rotate(-15deg);
}

.background-pic {
  position: absolute;
  height: 100vh;
  z-index: 2;
  overflow: hidden;
  right: 0;
  top: 40vh;
  transform: rotate(-15deg);
}

.background-pic img {
  height: 100%;
}

.menu {
  position: absolute;
  right: 0px;
  color: #f6f0c4;
  display: flex;
  justify-content: flex-end;
  width: 50%;
  padding: 2em 0em;
  z-index: 100;
}

.background-flowers {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: -100;
}

.background-flowers img {
  height: 100%;
  width: 100%;
  z-index: -100;
  opacity: 1;
}

.element {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 1em;
  width: 100px;
  text-align: center;
}

.element:hover {
  color: black;
}

.dialogue-container {
  position: absolute;
  display: flex;
  font-family: "Poppins";
  justify-content: "flex-start";
  width: 70vw;
  top: 15%;
  font-size: clamp(2rem, -3.0714rem + 10.4286vw, 7.5rem);
  line-height: 0.9em;
  font-weight: 800;
  color: #f6f0c4;
  margin-left: 0.5em;
  margin-top: 5%;
  animation: 0.3s ease-out 1s 2 blink;
  z-index: 1000;
}

.dialogue {
  display: block;
  font-family: "Poppins";
  font-size: 1.75em;
  width: 100%;
  margin-left: 3em;
  margin-top: 4em;
  z-index: 1000;
  animation: 0.3s ease-out 1s 2 blink;
}

.dialogue span {
  font-weight: bold;
  padding: 0 0.15em 0 0.15em;
}

.pinkunderline {
  font-family: "Poppins";
  color: rgb(40, 158, 255);
  text-transform: uppercase;
  font-size: smaller;
}

.unit {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

/* visited link */
a:visited {
  text-decoration: none;
}

/* mouse over link */
a:hover {
  text-decoration: none;
}

/* selected link */
a:active {
  text-decoration: none;
}

.icon {
  font-size: 2em;
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.4);
}

.js-pic img {
  width: 100%;
  animation: 0.5s ease-out 0s 1 slideInFromRight;
}

.header-home {
  color: #f6f0c4;
  font-weight: 800;
  font-size: 7.5rem;
  line-height: 6.5rem;
  text-transform: uppercase;
}

.testington {
  display: flex;
  flex: 5;
  color: "white";
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.8rem;
  padding: 1rem 1em;
  font-family: "Poppins";
}

@media all and (max-width: 1600px) and (min-width: 800px) {
  .header-home {
    font-size: 5.5rem;
    line-height: 4.5rem;
  }
  .background-pic {
    position: absolute;
    height: 90vh;
    z-index: 2;
    overflow: hidden;
    right: 0;
    top: 40vh;
    transform: rotate(-15deg);
  }

  .dialogue-container {
    position: absolute;
    display: flex;
    font-family: "Poppins";
    justify-content: "flex-start";
    width: 70vw;
    top: 15%;
  }
}

@media all and (max-width: 800px) and (min-width: 400px) {
  .menu {
    display: flex;
    justify-content: center;
    width: 80vw;
    padding: 1em 0em;
    margin: auto;
  }
  .element {
    font-family: "Poppins";
    font-weight: 800;
    font-size: 1em;
    text-transform: uppercase;
    width: 100px;
    font-stretch: expanded;
    text-align: center;
    margin-right: 0;
  }
  .header-home {
    font-size: 3.5rem;
    line-height: 2.5rem;
  }
  .background-pic {
    position: absolute;
    height: 70vh;
    z-index: 2;
    overflow: hidden;
    right: 0;
    top: 60vh;
    transform: rotate(-15deg);
  }
  .dialogue-container {
    width: 100vw;
  }
  .dialogue {
    width: 100vw;
    margin: auto;
  }
  .icon {
    font-size: 3em;
  }
  .testington {
    display: flex;
    flex: 5;
    color: "white";
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.8rem;
    padding: 1rem 0.5em;
    font-family: "Poppins";
  }
}

@media all and (max-width: 399px) and (min-width: 0px) {
  .testington {
    display: flex;
    flex: 5;
    color: "white";
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.8rem;
    padding: 1rem 0.5em;
    font-family: "Poppins";
  }

  .menu {
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
    padding: 1em;
  }

  .element {
    font-family: "Poppins";
    font-weight: 800;
    font-size: 0.75em;
    text-transform: uppercase;
    width: 10vw;
    font-stretch: expanded;
    text-align: center;
    margin-right: 0.5em;
  }
  .header-home {
    color: #f6f0c4;
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    width: 100vw;
  }
  .background-pic {
    position: absolute;
    height: 125vh;
  }
  .dialogue-container {
    width: 100vw;
  }
  .dialogue {
    width: 90vw;
    margin: auto;
  }

  .icon {
    font-size: 3em;
  }

  .background-pic {
    position: absolute;
    height: 50vh;
    z-index: 2;
    overflow: hidden;
    /* opacity: 0.75; */
    right: 0px;
    top: 60vh;
    transform: rotate(-15deg);
  }

  .background-pic img {
    height: 100%;
  }
}

.slidedown {
  /* This section calls the slideInFromLeft animation we defined above */
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}

.slideup {
  /* This section calls the slideinFromTop animation we defined above */
  animation: 0.5s ease-out 0s 1 slideInFromTop;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* herre */

/* .nav {
  position: fixed;
  z-index: 2;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
} */

/* .logo {
  text-decoration: none;
  color: #fff;
  font-family: "Monument Extended";
  font-weight: bolder;
  text-transform: uppercase;
} */

.menu-toggle {
  cursor: pointer;
}

.nav-overlay {
  position: absolute;
  background: #18181c;
  background-image: url("https://media2.giphy.com/media/bLdgTj2jCKe9Wf94Km/giphy.gif?cid=ecf05e47vdau0p00o7xdwvus7okytal6pgyib4hsy1cwi6wd&ep=v1_gifs_related&rid=giphy.gif&ct=g");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  /* z-index: -2000; */
  top: -100%;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 4em;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.hamBox {
  position: relative;
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s ease;
  padding: 2.4em;
}

.hamBox:hover {
  background: #2a2a2f;
}

.hamBoxOpen {
  background: #2a2a2f;
}

.lineTop,
.lineBottom {
  margin: 0 auto;
  position: absolute;
  display: block;
  width: 24px;
  height: 2px;
  border-radius: 10px;
  background: whitesmoke;
  left: 0;
  right: 0;
  transform: rotate(0deg);
  transition: all 0.4s;
}

.lineTop {
  top: 2.2em;
}

.lineBottom {
  bottom: 2.2em;
}

.lineTop.spin {
  top: 2.35em;
  transform: rotate(135deg);
  background: #fff;
}

.lineBottom.spin {
  bottom: 2.35em;
  transform: rotate(225deg);
  background: #fff;
}

ul.nav-links {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ul.nav-links li {
  list-style: none;
  position: relative;
}

ul.nav-links li a {
  text-decoration: none;
  color: #fff;
  font-family: "Rosseta";
  font-size: 6em;
  position: relative;
  top: 0;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.nav-item-wrapper:after {
  content: "";
  position: absolute;
  top: 120px;
  left: -10%;
  width: 120%;
  height: 100%;
  /* background: #18181c; */
  margin: 0 auto;
}

.nav-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 2em;
  display: flex;
  justify-content: space-between;
}

.nav-social-media ul {
  display: flex;
}

.nav-social-media ul li {
  list-style: none;
}

.nav-social-media ul li a {
  position: relative;
  bottom: -20px;
  text-decoration: none;
  font-family: sans-serif;
  color: #fff;
  text-transform: uppercase;
  padding-left: 2em;
  opacity: 0;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.location {
  position: relative;
  bottom: -20px;
  opacity: 0;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  font-family: sans-serif;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  ul.nav-links li a {
    font-size: 5em;
  }

  .location {
    display: none;
  }

  .nav-footer {
    justify-content: space-around;
  }

  .nav-social-media ul li a {
    padding: 0 1em;
    display: none;
  }
}
