.example {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 33vw;
  height: 100vh;
}

.project-details {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: right;
  width: 600px;
}

.headline {
  flex-direction: row;
  text-align: left;
  font-size: 3em;
  font-weight: 800;
}

.title-pic-container {
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-end;
}

.type {
  margin-bottom: 1em;
  text-transform: lowercase;
  font-size: small;
}

.pic {
  height: 60px;
  width: 60px;
}

.pic img {
  width: 100%;
}

.grayfilter {
  position: absolute;
  height: 100vh;
  width: 33vw;
  z-index: -1;
  overflow: hidden;
  opacity: 0.9;
}

.grayfilter img {
  height: 100%;
}

.gallery-content {
  margin: 90% 5%;
}

.title {
  font-size: 1.3em;
  display: none;
  font: small-caps;
}

.example:hover > .gallery-content > .title {
  display: block;
}

.example:hover > .gallery-content > .headline {
  margin-top: -5%;
  transition-delay: 0s;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier();
}

/* effects */
.rotate {
  transform: rotate(-90deg);
}

/* media queries */
@media all and (max-width: 1100px) and (min-width: 500px) {
  .example {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100vw;
    height: 80vh;
  }

  .grayfilter {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    overflow: hidden;
    opacity: 1;
  }

  .grayfilter img {
    height: 100%;
    width: 100%;
  }
  .gallery-content {
    margin: 50% 5%;
  }
}

@media all and (max-width: 500px) and (min-width: 0px) {
  .example {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100vw;
    height: 80vh;
  }

  .grayfilter {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    overflow: hidden;
    opacity: 1;
  }

  .grayfilter img {
    height: 100%;
    /* width: 100%; */
  }
  .gallery-content {
    margin: 50% 5%;
  }
}
