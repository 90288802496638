.hamBox {
    position: relative;
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.3s ease;
    padding: 2.4em;
    box-sizing: border-box;
  }
  /* * {
    margin: 0;
    padding: 0;

  } */
  .hamBox:hover {
    background: white;
  }
  
  .hamBoxOpen {
    background: white;
  }
  
  .lineTop,
  .lineBottom {
    margin: 0 auto;
    position: absolute;
    display: block;
    width: 24px;
    height: 2px;
    border-radius: 10px;
    background: white;
    left: 0;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.4s;
  }
  
  .lineTop {
    top: 2.2em;
  }
  
  .lineBottom {
    bottom: 2.2em;
  }
  
  .lineTop.spin {
    top: 2.35em;
    transform: rotate(135deg);
    background: #fff;
  }
  
  .lineBottom.spin {
    bottom: 2.35em;
    transform: rotate(225deg);
    background: #fff;
  }
