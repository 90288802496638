.case-container {
  color: white;
  height: 100vh;
  width: 100vw;
}

.case-hero {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  /* opacity: .9;
  clip-path: polygon(0 0, 42% 0, 69% 100%, 0% 100%);
  animation: 0.5s ease-out 100ms 1 slideInFromLeft; */
}

.blue-gradient {
  /* can be treated like a fallback */
  background-color: dodgerblue;

  /* will be "on top", if browser supports it */
  background-image: linear-gradient(rgba(30, 143, 255, 0.009), dodgerblue);

  /* these will reset other properties, like background-position, but it does know what you mean */
  background: dodgerblue;
  background: linear-gradient(rgba(30, 143, 255, 0.009), dodgerblue);
}

.case-screen {
  height: 100vh;
  width: 100vw;
}

.case-title {
  padding-top: 60vh;
  padding-left: 10vh;
  font-size: clamp(3rem, -3.0714rem + 10.4286vw, 6rem);
  font-weight: 800;
  z-index: 1;
  position: absolute;
}

.gradient-text {
  padding-top: 60vh;
  padding-left: 10vh;
  font-size: clamp(3rem, -3.0714rem + 10.4286vw, 6rem);
  font-weight: 800;
  z-index: 1000;
  position: absolute;
  /* Fallback: Set a background color. */
  background-color: red;

  /* Create the gradient. */
  background-image: linear-gradient(45deg, #f3ec78, #af4261);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.case-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.more-info {
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: 1.5s ease-out 0s 1 fadeInFromNone;
}

.fadein {
  animation: 1s ease-out 0s 1 fadeInFromNone;
}

.hide {
  display: none;
  width: 50%;
  height: 100%;
}

.white {
  background-color: white;
  height: 70vh;
}

.black-font {
  opacity: 0.9;
  color: black;
}

.info-header {
  font-weight: 600;
  margin: 0.5em 0em;
  font-size: 1.3em;
  opacity: 0.6;
}

.tech-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 50vw;
}

.left-detail-container {
  width: 65%;
  height: auto;
}
.detail-container {
  width: 50%;
  height: auto;
}

.tech {
  padding: 0 5px;
}

.desc {
  text-align: left;
  font-size: clamp(1rem, -2.0714rem + 10.4286vw, 1.5rem);
  /* padding: 0px 1.5em; */
}

.role {
  font-weight: 600;
  /* text-transform: uppercase; */
  font-size: clamp(0.5rem, -1.0714rem + 10.4286vw, 1.2rem);
}

.bottom {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  position: absolute;
  z-index: -2;
}

.non-katy-footer {
  width: 50%;
  height: 100vh;
  margin: auto;
  overflow: hidden;
}

.grey {
  height: 100vh;
  width: 100vw;
  background-color: #efefef;
  overflow: hidden;
}

.case-screen-bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.case-hero-bg {
  width: 100%;
  height: 100%;
}

.logo {
  text-align: center;
}

.logo img {
  height: 4em;
}

.menubtn {
  background-color: white;
  color: black;
  border-radius: 50%;
  padding: 0.5em;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.curbio-logo img {
  width: 100%;
}

.curbio-hand {
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 30%;
}

.footer-bg {
  z-index: 1;
  height: "60%";
  max-width: "auto";
}

/* media queries */
@media all and (max-width: 940px) and (min-width: 500px) {
  .case-title {
    padding-top: 60vh;
    padding-left: 5vh;
  }
  .case-description {
    flex-direction: column;
  }
  .more-info {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .desc {
    text-align: center;
    padding: 15px;
  }

  .role {
    font-weight: 500;
    /* text-transform: uppercase; */
    font-size: clamp(0.5rem, -1.0714rem + 10.4286vw, 1rem);
  }

  .detail-container {
    width: 80%;
    height: auto;
    padding: 15px;
  }

  .tech-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100vw;
  }
}

@media all and (max-width: 500px) and (min-width: 0px) {
  .curbio-hand {
    position: block;
    bottom: 40%;
    right: 10%;
    width: 30%;
  }

  .case-screen {
    height: 60vh;
    width: 100%;
  }

  .case-hero {
    position: absolute;
    height: 60vh;
    width: 100%;
    z-index: -1;
    /* opacity: .9;
    clip-path: polygon(0 0, 42% 0, 69% 100%, 0% 100%);
    animation: 0.5s ease-out 100ms 1 slideInFromLeft; */
  }

  .footer-bg {
    z-index: 1;
    height: "60%";
    max-width: "auto";
  }

  .case-title {
    padding-top: 30vh;
    padding-left: 5vh;
  }
  .case-description {
    flex-direction: column;
  }
  .more-info {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .desc {
    text-align: center;
    padding: 15px;
  }

  .role {
    font-weight: 500;
    /* text-transform: uppercase; */
    font-size: clamp(0.5rem, -1.0714rem + 10.4286vw, 1rem);
  }

  .detail-container {
    width: 80%;
    height: auto;
    padding: 15px;
  }

  .tech-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100vw;
  }

  .logo {
    text-align: center;
  }

  .logo img {
    height: 2em;
  }

  .white {
    background-color: white;
    height: 100vh;
  }
  .curbio-logo img {
    width: 50%;
  }
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.screenshot-container-curbio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow: hidden;
  height: 30rem;
}

/* .screenshot-container-curbio img {
  flex: 1,
} */

.screenshot-curbio {
  height: 100%;
}

.video-container-curbio iframe {
  display: flex;
  /* display: inline-block; */
  align-self: center;
  margin: auto;
  height: 80vh;
  width: 80vw;
  padding: 3rem;
}

@media all and (max-width: 940px) and (min-width: 0px) {
  .screenshot-container-curbio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    overflow: hidden;
    height: 10rem;
  }

  .screenshot-curbio {
    height: 100%;
  }

  .video-container-curbio iframe {
    display: flex;
    align-self: center;
    margin: auto;
    height: 35%;
    width: 70%;
    padding: 15%;
  }
}
