.Skills {
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: scroll;
  z-index: 0;
  background-color: black;
}

.contactme {
  margin: auto;
  font-weight: 800;
  color: black;
  width: 90vw;
  text-align: left;
  padding: 1em 0 1em;
}

.icons {
  display: flex;
  width: 85vw;
  max-width: 1007px;
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 5em;
  align-items: center;
  justify-content: center;
  color: white;
}

.skill-card {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  text-align: center;
  font-size: 1em;
  padding: 1em;
  margin: 0.5em;
  transition: all 0.2s ease-in-out;
}

.skill-card img {
  width: 100%;
  height: 90%;
}

.tiny-icon {
  flex: 3;
  font-size: 3em;
  max-height: 82px;
  justify-content: center;
  align-items: center;
}

.skill-card:hover {
  transform: scale(1.2);
}

.gradient-text {
  background-color: white;
  background-image: linear-gradient(45deg, #f3ec78, #af4261);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.phones {
  /* width: 75vw;
  height: 75vh; */
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  transform: rotate(180deg);
  z-index: -100;
  filter: grayscale(100%);
}

.stars {
  position: absolute;
  top:0;
  left:0;
  z-index: -100;
}

.cat {
  position: absolute;
  top:0;
  right: 5%;
  z-index: -1;
}